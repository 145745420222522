
import ApiService from '../../../services/api'
import { useEffect, useState } from "react";
import { Module } from "../../modules/models/module";
import React from 'react';
import { Payment } from '../../payments/models/payment';
import env from '../../../env';

export const requestStatus = {
    draft               : { value:"draft",                  label:"bozza",                      color: "disabled"},
    cancelled           : { value:"cancelled",              label:"annullata",                  color: "error"},
    under_review        : { value:"under_review",           label:"in revisione",               color: "primary"},
    waiting_payment     : { value:"waiting_payment",        label:"in attesa di pagamento",     color: "primary"},
    waiting_review      : { value:"waiting_review",         label:"in attesa di revisione",     color: "primary"},
    waiting_integration : { value:"waiting_integration",    label:"in attesa di integrazione",  color: "primary"},
    approved            : { value:"approved",               label:"approvata",                  color: "success"},
    completed           : { value:"completed",              label:"completata",                 color: "success"}
}

export class RequestDataUpdate {
    id:Number;
    values:any;
    user:any;
    created:Date;

    constructor(data:any){
        this.id =           data.id;
        this.values =       data.values;
        this.user =         data.user;
        this.created =      new Date(data.created);
    }
}

export class RequestStatusUpdate {
    //id:string;
    status:any;
    user:any;
    user_fullname:any;
    created:Date;
    note:string;

    constructor(data:any){
        //this.id =           data.id;
        this.status =           data.status;
        this.user =             data.user;
        this.user_fullname =    data.user_fullname;
        this.created =          new Date(data.created);
        this.note =             data.note ?? "";
    }
}


export class Request {

    id:string;
    //values:any;
    user:any;
    created:Date;
    modified:Date;

    dataUpdates:RequestDataUpdate[];
    statusUpdates:RequestStatusUpdate[];
    payments:Payment[];
    paymentsCount:number;

    protocolSent?:Date;
    protocolSubject?:string;
    protocolId?:string;
    protocolAssigned?:Date;

    module:any;

    //status:string;

    get values() {
        return this.dataUpdates[0].values;
    } 

    get lastUpdate() {
        return new Date(this.dataUpdates[0].created);
    } 
    getLastUpdatePdfUrl(sportellodigitale_config:any) {
        return sportellodigitale_config.backend_url + 'api/requests/pdf/' + this.id + '/' + this.dataUpdates[0].id;
    }
    getLastUpdateIMUPdfUrl(sportellodigitale_config:any, element:any, type:string) {
        return sportellodigitale_config.backend_url + 'api/requests/imu_pdf/' + this.id + '/' + this.dataUpdates[0].id + "/" + element.refCode + "/" + element.refYear + "/" + element.id + "/" + type;
    }
    get status() {
        return this.statusUpdates[0].status;
    } 


    get isEditable() {
        return this.status === 'draft' || this.status == 'waiting_integration';
    }
    get isDraft() {
        return this.status === 'draft';
    }

    get sent() {
        var sent = null;
        this.statusUpdates.forEach((statusUpdate) => {
            if (statusUpdate.status == 'waiting_review') {
                sent = statusUpdate.created;
            }
        });
        return sent;
    }
    get reviewed() {
        var reviewed = null;
        this.statusUpdates.forEach((statusUpdate) => {
            if (statusUpdate.status == 'under_review') {
                reviewed = statusUpdate.created;
            }
        });
        return reviewed;
    }
    get expected_completion() {
        if (this.sent != null && this.module.service.completion_days > 0) {
            var expected_completion = new Date(this.sent);
            expected_completion.setDate(expected_completion.getDate() + this.module.service.completion_days);
            return expected_completion;
        }
        return null;
    }

    constructor(data:any){
        this.id =           data.id;
        //this.values =       data.values;
        this.user =         data.user;
        this.created =      new Date(data.created);
        this.modified =     new Date(data.modified);

        this.protocolSent       = data.protocol_sent ? new Date(data.protocol_sent) : undefined;
        this.protocolSubject    = data.protocol_subject;
        this.protocolId         = data.protocol_id;
        this.protocolAssigned   = data.protocol_assigned ? new Date(data.protocol_assigned) : undefined;

        this.dataUpdates    = data.request_data_update?.map((data_update:any) => new RequestDataUpdate(data_update)) || [];
        this.statusUpdates  = data.request_status_update?.map((status_update:any) => new RequestStatusUpdate(status_update)) || [];
        this.payments       = data.payment?.map((payment:any) => new Payment(payment)) || [];
        this.paymentsCount  = data.request_payments_count;

        this.module =       data.module;

        //this.status =       data.status;
    }
}


export const useRequest = (uuid:string | undefined) => {
    const [request, _setRequest]     = useState(new Request({}));
    const [isLoading, _setIsLoading] = useState(true);

    useEffect(() => {
        fetchRequest();
    }, []);

    
    const setRequest = (request:Request) => {
        _setRequest(request);
    }
    const setIsLoading = (isLoading:boolean) => {
        _setIsLoading(isLoading);
    }

    const fetchRequest = async () => {
        try{
        let request = await ApiService.getRequest(uuid);
            _setRequest(request);
        }catch(e){
            //_setRequest(null);
        }
        setIsLoading(false);
    }

    
  
    return {
        request,
        setRequest,

        fetchRequest,

        isLoading,
        setIsLoading,
    };
}

export const useRequestDefaultValue = {
    request: new Request({}),
    setRequest: (request:Request) => {},

    fetchRequest: ()=>{},

    isLoading: false,
    setIsLoading: (isLoading:boolean) => {},
    
}

export const RequestContext = React.createContext(useRequestDefaultValue);
